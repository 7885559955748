<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('open_stock_items.inventory')}}</label>
                            <multiselect v-model="inventory"
                                :placeholder="$t('open_stock_items.inventory')"
                                label="name"
                                track-by="id"
                                :options="inventories"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('open_stock_items.financial_year_name')}}</label>
                            <multiselect v-model="financial_year"
                                :placeholder="$t('open_stock_items.financial_year_name')"
                                label="name"
                                track-by="id"
                                :options="financial_years"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('open_stock_items.item')}}</label>
                            <multiselect v-model="item"
                                :placeholder="$t('open_stock_items.item')"
                                label="name"
                                track-by="id"
                                :options="items"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getItems($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('open_stock_items.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('open_stock_items.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>
                     
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('item_open_stock.export_data_open_quantity_history')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.open_stock_items')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                   
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->
       
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-open-stock-items",
        data() {
            return {
                mainRoute: 'items/item-open-stock/log',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    inventory_id: '',
                    item_id: '',
                    financial_year_id: '',
                    from_date: '',
                    to_date: '',
                },
                inventory: null,
                inventories: [],
                financial_years : [],
                financial_year: null,
                items: [],
                item: null,

                columns: ['inventory_name', 'financial_year_name','item_name', 'qty','created_at'],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('open_stock_items.inventory')] = 'inventory_name';
                fields[this.$t('open_stock_items.financial_year_name')] = 'financial_year_name';
                fields[this.$t('open_stock_items.item')] = 'item_name';
                fields[this.$t('open_stock_items.quantity')] = 'qty';
                fields[this.$t('open_stock_items.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        inventory_name: that.$t('open_stock_items.inventory'),
                        financial_year_name: that.$t('open_stock_items.financial_year_name'),
                        item_name: that.$t('open_stock_items.item'),
                        qty: that.$t('open_stock_items.quantity'),
                        created_at: that.$t('open_stock_items.created_at'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        watch:{
             financial_year: function (val) {
                if (val){
                    this.filters.financial_year_id = val.id;
                }else {
                    this.filters.financial_year_id = null;
                }
            },
            inventory: function (val) {
                if (val){
                    this.filters.inventory_id = val.id;
                }else {
                    this.filters.inventory_id = null;
                }
            },
            item: function (val) {
                if (val){
                    this.filters.item_id = val.id;
                }else {
                    this.filters.item_id = null;
                }
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.open_stock_items")}]);
            this.getInventories();
            this.getFinancialYears();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.inventory = null;
                this.financial_year = null;
                this.filters.financial_year_id = null;
                this.item = null;
                this.filters.inventory_id = null;
                this.filters.item_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;


                this.$refs.table.refresh();
            },
         
            
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            getFinancialYears() {
                ApiService.get(this.mainRouteDependency + "/all_financial_years").then((response) => {
                    this.financial_years = response.data.data.map((row) => {
                        return {id: row.id, name: row.start_date + ' - ' + row.end_date};
                    });
                });
            },
         
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            
            getItems(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/items`,{params:{filter: filter}}).then((response) => {
                        this.items = response.data.data;
                    });
                }else{
                    this.items = [];
                }
            },
        },
    };
</script>
